<template>
<div class="bg-orange">
    <header class="header">
        <div class="header-left hidden">
            <a href="#">
                <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
            </a>
        </div>
        <div class="header-center">
        </div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="card card-800 card-centerred">
                <div class="card-close">
                    <a @click="goNext()" class="button button-small button-brown button-close">Bezár</a>
                </div>
                <div class="card-title mt-64 mb-86">
                    Nem te vagy<br>
                    {{nickname}}?
                </div>
                <div class="card-info">
                    <p class="mb-32">Nézd meg, nem keverted-e össze valakivel a kártyáitokat!</p>
                    <p class="mb-64">Szólj a személyzetnek.</p>
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
        <div class="container">
            <div class="footer-button-only">
                <a @click="goNext()" class="button button-brown button-close">Bezár</a>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "PersonCheck",
    computed: {
        ...mapGetters(["nickname"])
    },
    methods: {
        goNext() {
            this.$router.go('-1')
        }
    }
}
</script>

<style>

</style>
